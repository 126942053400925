import { createStore } from 'vuex'

export default createStore({
  state: {
    // 登录状态
    Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',
    login_time:localStorage.getItem('login_time')?localStorage.getItem('login_time'):''
  },
  getters: {
  },
  mutations: {
    // 修改登录状态
    changeLogin (state,user ) {
      state.Authorization = user.Authorization
      localStorage.setItem('Authorization', user.Authorization)
    },
    // 修改登录过期时间
    changeLoginTime (state,user) {
      state.login_time = user.login_time
      localStorage.setItem('login_time', user.login_time)
    }
  },
  actions: {
  },
  modules: {
  }
})
