import axios from 'axios'

const instance = axios.create({
  baseURL: 'http://localhost:8003/'
})

instance.interceptors.request.use((config) => {
  // 在发送请求之前做些什么
  const token = window.sessionStorage.getItem('token')
  if (token) {
    config.headers.Authorization = token
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

instance.interceptors.response.use(response => {
  return response.data
})

export default instance
