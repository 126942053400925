<template>
  <router-view/>
  <office-sign-foot/>
</template>

<script>
import OfficeSignFoot from "@/components/OfficeSignFoot.vue";
export default {
  name: 'App',
  components:{
    OfficeSignFoot
  },
  data() {
    return {
      audio : new  Audio(),
      successUrl:require("./assets/index.mp3"),
    }
  },
  created() {
    this.autoPlay();
  },
  mounted() {

  },
  methods:{
    autoPlay(){
      //播放音乐
      this.audio.src = this.successUrl
      this.audio.autoplay = true
    }
  }
}
</script>