//https://blog.csdn.net/weixin_46064349/article/details/105647823
document.onkeydown = document.onkeyup = document.onkeypress = function (event) {
  let e = event || window.event || arguments.callee.caller.arguments[0];
  //禁用网页保存
  if (e && e.ctrlKey === true && e.keyCode === 83) {
    e.returnValue = false;
    return false;
  }

  // 禁用F12控制台
  // if (e && e.keyCode === 123) {
  //   e.returnValue = false;
  //   return false;
  // }
}

//禁用右键菜单
document.oncontextmenu = function () {
  event.returnValue = false;
}
//禁用网页复选
document.onselectstart = function () {
  event.returnValue = false;
}
//禁用网页复制
document.oncopy = function () {
  event.returnValue = false;
}
//禁用网页粘贴
document.onpaste = function () {
  event.returnValue = false;
}
//禁用网页剪贴
document.oncut = function () {
  event.returnValue = false;
}


