<template>
  <div class="foot" id="officeinfofoot">
    <div style="float: left;">{{ info }}</div>
    <div style="float: left;" class="contect">{{ contect }}
      <div class="qr-container">
        <img style="width:90px; border-radius: 8px;margin-top: 5px" src="../assets/QR-wechat.png" alt="微信/Wechat">
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "OfficeSignFoot",
  data() {
    return {
      info: '-\u00A0\u00A0滇ICP备20004544号-1\u00A0\u00A0\u00A0\u00A0\u00A0Copyright\u00A0\u00A0\u00A0©\u00A0\u00A0\u00A02020-2023\u00A0\u00A0\u00A0\u00A0\u00A0codeBy\u00A0\u00A0\u00A0IAO\u00A0\u00A0\u00A0',
      contect: '联系方式',
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.foot {
  /*width: 100%;*/
  height: 40px;
  color: #FFFFFF;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%,0);
  /*background: #fcb045;*/
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  line-height: 40px;
  text-align: center;
  z-index: 1;
  /*margin: auto;*/

}

.contect {
  width: 100px;
  float: left;
  cursor: pointer;
}
.contect:hover{
  font-weight: bold;
}
.contect:hover .qr-container{
  visibility: visible;
  opacity: 1;
}

.qr-container{
  position: fixed;
  bottom: 40px;
  width: 100px;
  height: 100px;
  z-index: 0;
  opacity: 0;
  visibility: hidden;
  transition: all .4s ease-in-out;
  background: white;
  border-radius: 8px;
}
</style>