import {createRouter, createWebHistory} from 'vue-router'
import {ElMessage} from "element-plus";

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('../views/MainIndex.vue'),
        meta: {
            title: '欢迎 - IAO'
        }
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/MainHome.vue'),
        meta: {
            title: '首页 - IAO'
        }
    },
    {
        path: '/info',
        name: 'IaoInfo',
        component: () => import('../views/IaoInfo.vue'),
        meta: {
            title: '收藏 - IAO'
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// 在跳转路由时设置网页标题
router.beforeEach((to, from, next) => {
    document.title = to.matched[0].meta.title
    next()
})

// 导航守卫
router.beforeEach((to, from, next) => {
    console.log(to.path)
    if (to.path === '/' || to.path === '/UserApply' || to.path === '/GoodTools') {
        next()
    } else {
        //判断是否保存登录信息
        const token = localStorage.getItem('Authorization')
        if (token === null || token === '') {
            ElMessage({
                message: '请先认证身份',
                type: 'error',
                showClose: true,
                center: true
            })
            next('/')
        } else {
            let login_time = parseInt(localStorage.getItem('login_time'))
            let now = new Date().getTime()
            //设置登录超期时间1小时
            let out_time = 3600000
            if ((login_time + out_time) > now) {
                //刷新登陆时间
                now = new Date().getTime()
                localStorage.setItem("login_time", now.toString())
                next()
            } else {
                //超时清空登陆状态
                localStorage.removeItem('Authorization')
                ElMessage({
                    message: '登录过期，请重新登录',
                    type: 'warning',
                    showClose: true,
                    center: true
                })
                next('/')
            }
        }
    }
})
export default router
