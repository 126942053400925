import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './request'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './css/index.css'
import './js/index-Tool.js'
import './js/index.js'

const app = createApp(App)

app.config.productionTip = false
app.config.globalProperties.$axios = axios

app.use(ElementPlus)
app.use(store)
app.use(router)

app.mount('#app')
